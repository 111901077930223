<template>
    <div>
        <mf-box :empty="!portCallId">
            <div slot="header">
                Portcall Timeline
            </div>
            <div class="portcall-timeline">
                <div class="portcall-timeline__header">
                    <div class="portcall-timeline__items">
                        <mf-dropdown without-icon :open-from-right="true">
                            <div class="field__item" slot="link">
                                <mf-icon class="field__icon" icon-name="icon-filter" />
                                <div class="field__text">Filter</div>
                            </div>
                            <mf-divider />
                            <div class="field__filter">
                                <div class="field__filter-title">
                                    Owned:
                                </div>
                                <template v-for="(filterByInnovatorOption, index) in filterByInnovatorOptions">
                                    <mf-divider
                                        :key="`divider-${filterByInnovatorOption}`"
                                        v-if="index !== filterByInnovatorOptions.length"
                                    />
                                    <div class="field__filter-item" :key="filterByInnovatorOption">
                                        <mf-checkbox
                                            @change="changeFilter(filterByInnovatorOption)"
                                            :checked="checkSelectedFilterOption(filterByInnovatorOption)"
                                        >
                                            {{ filterByInnovatorOption }}
                                        </mf-checkbox>
                                    </div>
                                </template>
                            </div>
                        </mf-dropdown>
                        <div
                            class="portcall-timeline__item"
                            :class="{ 'portcall-timeline__item_selected': overlap }"
                            @click="toggleOverlap"
                        >
                            <mf-icon class="portcall-timeline__icon" icon-name="icon-check" />
                            <div class="portcall-timeline__text">
                                {{ overlap ? 'Hiding overlap' : 'Showing overlap' }}
                            </div>
                        </div>
                        <div
                            class="portcall-timeline__item"
                            :class="{ 'portcall-timeline__item_selected': !expired }"
                            @click="toggleExpired"
                        >
                            <mf-icon class="portcall-timeline__icon" icon-name="icon-hide" />
                            <div class="portcall-timeline__text">
                                {{ expired ? 'Showing Expired' : 'Hiding Expired' }}
                            </div>
                        </div>
                        <div
                            class="portcall-timeline__item"
                            :class="{ 'portcall-timeline__item_disabled': selectedScale === MAX_SCALE }"
                            @click="increaseZoom"
                        >
                            <mf-icon class="portcall-timeline__icon" icon-name="icon-zoom-in" />
                            <div class="portcall-timeline__text">Zoom in</div>
                        </div>
                        <div
                            class="portcall-timeline__item"
                            :class="{ 'portcall-timeline__item_disabled': selectedScale === MIN_SCALE }"
                            @click="decreaseZoom"
                        >
                            <mf-icon class="portcall-timeline__icon" icon-name="icon-zoom-out" />
                            <div class="portcall-timeline__text">Zoom out</div>
                        </div>
                    </div>
                </div>
                <mf-divider />
                <mf-responsive-table v-if="haveEventsForDisplay">
                    <div class="portcall-timeline__table">
                        <div class="portcall-timeline__past-dates" :style="pastDatesContainerStyle" />
                        <time-labels
                            :number-of-time-labels="numberOfTimeLabels"
                            :start-date="startDateForTimeLine"
                            :time-coefficient="timeCoefficient"
                        />
                        <mf-divider />
                        <template v-for="(timelineLabel, index) in timelineLabels">
                            <div
                                class="portcall-timeline__line"
                                :key="timelineLabel"
                                :class="index % 2 && 'portcall-timeline__line_light'"
                                :style="lineStyle"
                            >
                                <div class="portcall-timeline__title">
                                    {{ timelineLabel.split('_').join(' ') }}
                                </div>
                                <div class="portcall-timeline__event-lines" :key="`lines-${timelineLabel}`">
                                    <div
                                        class="portcall-timeline__event-line"
                                        v-for="(eventLine, index) in eventLines[timelineLabel]"
                                        :key="`${timelineLabel}-line-${index}`"
                                    >
                                        <event
                                            v-for="event in eventLine"
                                            :key="event.id"
                                            :event="event"
                                            @toggleModal="toggleModal"
                                        />
                                    </div>
                                </div>
                            </div>
                            <mf-divider :key="`divider-${timelineLabel}`" />
                        </template>
                    </div>
                </mf-responsive-table>
                <div v-else class="portcall-timeline__empty">
                    {{ emptyText }}
                </div>
            </div>
            <div slot="empty-text">
                No portcall chosen
            </div>
        </mf-box>
        <event-modal v-if="selectedEvent" :event="selectedEvent" :is-open="isModalShown" @close="toggleModal" />
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import TimeLabels from '../timeline-components/TimeLabels';
    import Event from '../timeline-components/Event';
    import * as TIMELINE_CONSTANTS from '../../config/timelineConstants';
    import EventModal from '@/modals/EventModal';

    const WIDTH_FOR_VESSELS_NAMES_BLOCK = 170;

    export default {
        name: 'PortcallTimeline',
        components: { Event, TimeLabels, EventModal },
        watch: {
            portCallId: {
                handler(val) {
                    val && this.$store.dispatch('getPortCallEvents', val);
                },
                immediate: true,
            },
        },
        computed: {
            ...mapGetters({ portCallId: 'selectedPortCallId', events: 'selectedPortCallEvents' }),
            timelineLabels() {
                return this.eventLines && Object.keys(this.eventLines);
            },
            eventLines() {
                return (
                    this.eventsWithWidthInfo &&
                    this.eventsWithWidthInfo.reduce((result, value) => {
                        if (!result[value.definitionId]) {
                            result[value.definitionId] = [[value]];
                        } else {
                            const position = this.findLineOnWhichEventCanBeDisplayed(value, result[value.definitionId]);
                            if (position > -1) {
                                result[value.definitionId][position].push(value);
                            } else {
                                const position = result[value.definitionId].length;
                                result[value.definitionId][position] = [value];
                            }
                        }
                        return result;
                    }, {})
                );
            },
            timeRange() {
                const eventsTimes =
                    this.events &&
                    this.events
                        .flatMap(event => [event.startTime, event.endTime])
                        .filter(Number)
                        .sort();

                return {
                    start: eventsTimes[0],
                    end: eventsTimes[eventsTimes.length - 1],
                };
            },
            timeLabels() {
                let labels = [];

                const firstLabel = Math.floor(this.timeRange.start);

                labels.push({
                    time: firstLabel,
                    isNewYear: true,
                    isNewMonth: true,
                    isNewDay: true,
                });

                let amountOfCreatedLabels = 1;

                while (amountOfCreatedLabels <= this.numberOfTimeLabels) {
                    let thisLabel = firstLabel + amountOfCreatedLabels * this.timeCoefficient;
                    let thisDate = new Date(thisLabel);
                    let previousDate = new Date(labels[amountOfCreatedLabels - 1].time);
                    let timelineLabel = {
                        time: thisLabel,
                        isNewYear: thisDate.getYear() !== previousDate.getYear(),
                        isNewMonth: thisDate.getMonth() !== previousDate.getMonth(),
                        isNewDay: thisDate.getDay() !== previousDate.getDay(),
                    };

                    labels.push(timelineLabel);
                    amountOfCreatedLabels++;
                }

                return labels;
            },
            eventsWithWidthInfo() {
                return (
                    this.displayedEvents &&
                    this.displayedEvents.map(event => {
                        let divWidth =
                            ((event.endTime - event.startTime) / this.timeCoefficient) *
                                TIMELINE_CONSTANTS.WIDTH_OF_PERIOD || 1;
                        let divMovement =
                            (((event.startTime || event.endTime) - this.startDateForTimeLine) / this.timeCoefficient) *
                            TIMELINE_CONSTANTS.WIDTH_OF_PERIOD;
                        event.style = {
                            left: `${divMovement + WIDTH_FOR_VESSELS_NAMES_BLOCK}px`,
                            width: `${divWidth}px`,
                        };
                        event.showTime = divWidth > 120;
                        return event;
                    })
                );
            },
            displayedEvents() {
                let displayedEvents =
                    this.events &&
                    this.events.filter(event => {
                        return (
                            (!this.filterByInnovator.length ||
                                this.filterByInnovator.includes(event.createdByUserOf)) &&
                            (this.expired || event.isExpired === this.expired) &&
                            (!this.overlap ||
                                !event.overlappingEvents.filter(overlapEvent => overlapEvent !== event.eventId).length)
                        );
                    });

                displayedEvents &&
                    displayedEvents.length &&
                    this.timeRange &&
                    displayedEvents.unshift({
                        definitionId: 'PORT VISIT',
                        startTime: this.timeRange.start,
                        startTimeType: 'ACTUAL',
                        endTime: this.timeRange.end,
                        endTimeType: 'ACTUAL',
                    });

                return displayedEvents;
            },
            numberOfTimeLabels() {
                const firstLabel = Math.floor(this.timeRange.start);
                const lastLabel = Math.ceil(this.timeRange.end);

                return Math.floor((lastLabel - firstLabel) / this.timeCoefficient) + 1;
            },
            timeCoefficient() {
                return (
                    TIMELINE_CONSTANTS.MILLISECONDS_IN_DAY_HALF / TIMELINE_CONSTANTS.SCALE_NUMBERS[this.selectedScale]
                );
            },
            lineStyle() {
                return {
                    width: `${WIDTH_FOR_VESSELS_NAMES_BLOCK +
                        (this.numberOfTimeLabels + 1) * TIMELINE_CONSTANTS.WIDTH_OF_PERIOD}px`,
                };
            },
            pastDatesContainerStyle() {
                const currentDate = Date.parse(new Date());
                let divWidth = 0;
                if (currentDate > this.timeRange.end) {
                    divWidth = (this.numberOfTimeLabels + 1) * TIMELINE_CONSTANTS.WIDTH_OF_PERIOD;
                } else if (currentDate > this.timeRange.start) {
                    divWidth =
                        ((currentDate - this.timeRange.start) / this.timeCoefficient) *
                        TIMELINE_CONSTANTS.WIDTH_OF_PERIOD;
                }
                return {
                    left: `${WIDTH_FOR_VESSELS_NAMES_BLOCK}px`,
                    width: `${divWidth}px`,
                };
            },
            emptyText() {
                return this.events && this.events.length ? 'All events have expired' : 'No events';
            },
            startDateForTimeLine() {
                const startMinutes = new Date(this.timeRange.start).getMinutes();
                return new Date(this.timeRange.start).setMinutes(startMinutes > 30 ? 0 : -30);
            },
            haveEventsForDisplay() {
                return this.events && this.events.length && this.displayedEvents && this.displayedEvents.length;
            },
        },
        data() {
            return {
                selectedScale: TIMELINE_CONSTANTS.MIN_SCALE,
                overlap: false,
                expired: false,
                isAbleToScroll: false,
                startX: 0,
                MIN_SCALE: TIMELINE_CONSTANTS.MIN_SCALE,
                MAX_SCALE: TIMELINE_CONSTANTS.MAX_SCALE,
                selectedEvent: {},
                isModalShown: false,
                filterByInnovatorOptions: ['Carmenta', 'Columbia'],
                filterByInnovator: [],
            };
        },
        methods: {
            toggleModal(event) {
                if (event && event.definitionId !== 'PORT VISIT') {
                    this.selectedEvent = event;
                    this.isModalShown = true;
                } else {
                    this.isModalShown = false;
                }
            },
            increaseZoom() {
                this.selectedScale += 1;
            },
            decreaseZoom() {
                this.selectedScale -= 1;
            },
            toggleOverlap() {
                this.overlap = !this.overlap;
            },
            toggleExpired() {
                this.expired = !this.expired;
            },
            findLineOnWhichEventCanBeDisplayed(event, existingLines) {
                return existingLines.findIndex(lineEvents => this.isEventCanBeDisplayedOnSameLine(event, lineEvents));
            },
            isEventCanBeDisplayedOnSameLine(event, existingEvents) {
                return (
                    event.startTime > Math.max(...existingEvents.map(existingEvent => existingEvent.endTime)) ||
                    event.endTime < Math.min(...existingEvents.map(existingEvent => existingEvent.startTime))
                );
            },
            checkSelectedFilterOption(option) {
                return this.filterByInnovator.includes(option);
            },
            changeFilter(option) {
                if (this.filterByInnovator.includes(option)) {
                    this.filterByInnovator = this.filterByInnovator.filter(existOption => existOption !== option);
                } else {
                    this.filterByInnovator.push(option);
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .portcall-timeline__header {
        margin-bottom: $space-20;
        display: flex;
        justify-content: flex-end;
    }

    .portcall-timeline__event-lines {
        display: flex;
        flex-direction: column;
    }

    .portcall-timeline__event-line {
        height: 48px;
    }

    .portcall-timeline__line_light {
        background: $marine-600;
    }

    .portcall-timeline__items {
        display: flex;
        align-items: center;
    }

    .portcall-timeline__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $white;
        font-size: $font-12;
        line-height: $font-14;
        cursor: pointer;
        text-align: center;
        &:hover {
            opacity: 0.8;
        }
        &:not(:first-child) {
            margin-left: $space-16;
        }
    }

    .portcall-timeline__item_selected {
        color: $blue-300;
    }

    .portcall-timeline__item_disabled {
        pointer-events: none;
        opacity: 0.3;
    }

    .portcall-timeline__icon {
        margin-bottom: $space-8;
    }

    .portcall-timeline__table {
        margin-top: $space-20;
        padding-bottom: $space-4;
        position: relative;
    }

    .portcall-timeline__title {
        font-weight: bold;
        font-size: $font-13;
        line-height: $font-18;
        display: flex;
        align-items: center;
        color: $white;
        opacity: 0.8;

        padding: $space-8;
        max-width: 170px;
    }

    .portcall-timeline__past-dates {
        position: absolute;
        height: 100%;
        z-index: 1;
        background: $marine-800;
        opacity: 0.7;
    }

    .portcall-timeline__line {
        display: flex;
        position: relative;
    }

    .portcall-timeline__event {
        position: absolute;
        padding: $space-8 0;

        display: flex;
        align-items: stretch;
        height: 48px;

        z-index: 1;
    }

    .portcall-timeline__event-timeline {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;

        position: relative;

        &:hover {
            opacity: 0.7;
            .portcall-timeline__event-popup {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .portcall-timeline__event-times {
        background: $marine-400;
        border: 1px solid $marine-300;
        border-radius: 2px;

        height: 14px;

        display: flex;
        justify-content: space-between;
    }

    .portcall-timeline__event-times_not-empty {
        padding: 0 $space-4;
    }

    .portcall-timeline__event-time {
        font-weight: 600;
        font-size: $font-12;
        line-height: $font-14;
        color: $white;
        opacity: 0.6;
    }

    .portcall-timeline__event-time_small {
        margin: 0 $space-4;
        font-size: $font-10;
        opacity: 1;
    }

    .portcall-timeline__event-icon {
        margin: 0 $space-4;
        color: $white;
    }

    .portcall-timeline__event-icons {
        display: flex;
        justify-content: space-between;
        height: 14px;
        margin-bottom: $space-4;
    }

    .portcall-timeline__event-popup {
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        display: none;
        align-items: center;
        margin-bottom: $space-4;
    }

    .popup__line {
        display: flex;
        font-size: $font-10;
        color: $white;
    }

    .portcall-timeline__empty {
        margin-top: $space-50;
        text-align: center;
        font-size: $font-16;
        line-height: $font-20;
        color: $marine-400;
        min-height: 50px;
    }

    .field__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: $white;
        font-size: $font-12;
        line-height: $font-14;
        cursor: pointer;
        &:hover {
            opacity: 0.8;
        }
    }

    .field__icon {
        margin-bottom: $space-8;
    }

    .field__filter {
        border: 1px solid $marine-500;
        box-sizing: border-box;
        border-radius: 6px;
    }

    .field__filter-title {
        margin: $space-10 $space-16;
        font-size: $font-22;
        font-weight: bold;
        color: $white;
    }

    .field__filter-item {
        padding: $space-14 $space-16;
        font-size: $font-16;
        font-weight: normal;
    }
</style>
