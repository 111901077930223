export const portcallStateMock = {
    messageId: 'urn:mrn:stm:portcdm:message:d30b0c1e-a1am-1e5b-asdg-beed0ba59f5f',
    portCallId: 'urn:mrn:stm:portcdm:port_call:nosvg:dab8c278-e390-4daa-b516-59b744b2a7d2',
    time: '2022-10-10T12:23:00Z',
    timeType: 'Estimated',
    reportedAt: '2022-04-06T14:50:44Z',
    reportedBy: 'leonid@alleo.tech',
    stateDefinition: 'Test Test Test TCommencedT',
    from: '',
    at: '05w',
    to: '',
    performingActor: null,
    comment: '',
    isWithdrawn: false,
    withdrawnStatement: null,
    createdByUserOf: 'Test',
    name: 'Test Operation Commenced',
};
