<template>
    <mf-box :empty="!vesselMMSI" without-inner-paddings>
        <div slot="header">
            Vessel Traffic
        </div>
        <iframe class="vessel-traffic" width="100%" :key="vesselMMSI" :src="src" />
        <div slot="empty-text">
            No portcall chosen
        </div>
    </mf-box>
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        name: 'VesselTraffic',
        computed: {
            ...mapGetters(['vesselMMSI']),
            src() {
                return `https://www.marinetraffic.com/en/ais/embed/zoom:9/centery:37.446/centerx:24.9467/maptype:0/shownames:false/mmsi:${this.vesselMMSI}/shipid:0/fleet:/fleet_id:/vtypes:/showmenu:false/remember:false`;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .vessel-traffic {
        border: 0;
        min-height: 204px;
        @include media($sm) {
            min-height: 318px;
        }
        @include media($md) {
            min-height: 429px;
        }
        @include media($lg) {
            min-height: 541px;
        }
        @include media($xlg) {
            min-height: 467px;
        }
        @include media($xl) {
            min-height: 612px;
        }
    }
</style>
