<template>
    <div class="statement">
        <header class="statement__header">
            <statement-icon v-if="statement.timeType" :type="statement.timeType" />
            {{ statement.stateDefinition }}
        </header>
        <div class="statement__info">
            <div class="statement__column">
                <div v-if="statement.time">
                    Time:
                    <span class="statement__value">
                        {{ statement.time | dateFilter('date, time') }}
                    </span>
                </div>
                <div>
                    At:
                    <span class="statement__value">
                        {{ statement.at }}
                    </span>
                </div>

                <div v-if="statement.from">
                    From:
                    <span class="statement__value">
                        {{ statement.from }}
                    </span>
                </div>

                <div v-if="statement.to">
                    To:
                    <span class="statement__value">
                        {{ statement.to }}
                    </span>
                </div>
                <div class="statement__comment">
                    Comment:
                    <span>
                        {{ displayedComment }}
                    </span>
                    <span v-if="clickableText" class="statement__comment-button" @click="toggleFullComment">
                        {{ clickableText }}
                    </span>
                </div>
            </div>
            <div class="statement__column">
                <div v-if="statement.reportedAt">
                    {{ statement.isWithdrawn ? 'Withdrawn At: ' : 'Reported At: ' }}
                    <span class="statement__value">
                        {{ statement.reportedAt | dateFilter('date, time') }}
                    </span>
                </div>
                <div>
                    Type:
                    <span class="statement__value">{{ statement.timeType }}</span>
                </div>
                <div>
                    {{ statement.isWithdrawn ? 'Withdrawn By: ' : 'Reported By: ' }}
                    <span class="statement__value">{{ statement.reportedBy }}</span>
                </div>
            </div>
            <div class="statement__column_left">
                <mf-button v-if="!statement.isWithdrawn" @click="toggleModal">
                    withdraw
                </mf-button>
                <div v-else class="statement__withdrawn-status">
                    withdrawn
                </div>
            </div>
        </div>
        <withdrawn-message-modal
            v-if="isShowModal"
            :statement="statement"
            @close="toggleModal"
        ></withdrawn-message-modal>
    </div>
</template>

<script>
    import StatementIcon from '@/components/statement-components/StatementIcon';
    import WithdrawnMessageModal from '@/modals/WithdrawnMessageModal';

    export default {
        name: 'StatementItem',
        components: { StatementIcon, WithdrawnMessageModal },
        props: {
            statement: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                showFullComment: false,
                isShowModal: false,
            };
        },
        computed: {
            clickableText() {
                if (this.statement.comment && this.statement.comment.length > 40) {
                    return this.showFullComment ? 'ok close' : 'show full';
                } else {
                    return false;
                }
            },
            displayedComment() {
                if (this.statement.comment) {
                    if (this.clickableText) {
                        return this.showFullComment && this.clickableText
                            ? this.statement.comment
                            : this.statement.comment.substring(0, 40) + '...';
                    }
                    return this.statement.comment;
                } else {
                    return '-';
                }
            },
        },
        methods: {
            toggleFullComment() {
                this.showFullComment = !this.showFullComment;
            },
            toggleModal() {
                this.isShowModal = !this.isShowModal;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .statement {
        &:not(:first-child) {
            margin-top: $space-12;
        }
    }

    .statement__header {
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: $font-18;
        line-height: $font-24;
        color: $white;
    }

    .statement__info {
        display: flex;
        flex-direction: column;
        margin: $space-12 0;
        padding-left: $space-40;

        font-size: $font-14;
        line-height: $font-24;
        color: $white;
        @include media($sm) {
            flex-direction: row;
        }
    }

    .statement__column {
        display: flex;
        flex-direction: column;
        @include media($sm) {
            width: 50%;
            flex-basis: 50%;
            &:not(:last-child) {
                margin-left: $space-16;
            }
        }
    }

    .statement__value {
        font-weight: 700;
    }

    .statement__comment {
        padding-right: $space-20;
    }

    .statement__comment-button {
        font-weight: 700;
        color: $blue-300;
        margin-left: 5px;
        cursor: pointer;
    }

    .statement__withdrawn-status {
        font-size: $font-13;
        color: $red-500;
        padding: 0 $space-12;
        border: 1px solid $red-500;
        border-radius: $border-radius-4;
    }
</style>
