<template>
    <mf-modal small :scrollable="false" @close="close">
        <transition name="fade">
            <div v-if="isLoading" class="withdrawn-modal__send-message-info">
                Sending data to PortCDM...
            </div>
        </transition>
        <div slot="header">
            Withdrawn
        </div>
        <div class="info">
            Are you sure you want to withdrawn the message?
        </div>
        <div slot="footer">
            <div class="info__buttons">
                <mf-button color="blue" @click="withdrawn" :disabled="isLoading">Withdrawn</mf-button>
                <mf-button @click="close" :disabled="isLoading">Cancel</mf-button>
            </div>
        </div>
    </mf-modal>
</template>
<script>
    import messageSubmissionServiceMSS from '@/services/message-submission-service/messageSubmissionServiceMSS';
    import { mapGetters } from 'vuex';

    export default {
        props: {
            statement: {
                type: Object,
                require: true,
            },
        },
        data() {
            return {
                isLoading: false,
            };
        },
        computed: {
            ...mapGetters(['vesselIMO']),
        },
        methods: {
            close() {
                if (!this.isLoading) {
                    this.$emit('close');
                }
            },
            async withdrawn() {
                this.isLoading = true;
                try {
                    const message = {
                        portCallId: this.statement.portCallId,
                        vesselId: this.vesselIMO,
                        messageId: this.statement.messageId,
                        createdByUserOf: this.statement.createdByUserOf,
                        stateDefinition: this.statement.stateDefinition,
                    };

                    await messageSubmissionServiceMSS.post(message, true);
                    await this.$store.dispatch('getPortCall', this.statement.portCallId);
                    await this.$store.dispatch('getPortCallStatements', this.statement.portCallId);
                    await this.$store.dispatch('getPortCallEvents', this.statement.portCallId);
                    this.$toasted.success('Data received');
                } catch {
                    this.$toasted.error('SENDING DATA FAILED!');
                } finally {
                    this.isLoading = false;
                    this.close();
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
    .info {
        text-align: center;
        color: $white;
        font-size: 18px;
        margin-bottom: $space-14;
    }

    .info__buttons {
        display: flex;
        justify-content: center;
        :first-child {
            margin-right: $space-20;
        }
    }

    .withdrawn-modal__send-message-info {
        position: absolute;
        top: 20px;
        right: 5%;
        padding: $space-12;
        background-color: $white;
        border-radius: $border-radius-4;
        border: 1px solid $marine-500;
        @include media($md) {
            top: 5%;
        }
    }

    @import '@/assets/sass/transitions/fade';
</style>
