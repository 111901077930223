<template>
    <mf-box :empty="!portCallId || !(locations && locations.length)">
        <div slot="header">
            Portcall Locations
        </div>
        <div v-if="locations && locations.length">
            <template v-for="(location, index) in locations">
                <div
                    class="location"
                    :key="`${index}-${location.URN}`"
                    v-if="!!(location.startTime && location.endTime)"
                >
                    <div class="location__status" :class="`location__status_${location.status}`">
                        <mf-icon v-if="location.status === 'check'" icon-name="icon-check" />
                        <mf-icon v-if="location.status === 'error'" icon-name="icon-close" />
                    </div>
                    <div>
                        <div class="location__name" @click="goToQuay(location.URN)">{{ location.name }}</div>
                        <div class="location__time">
                            {{ displayTime(location.startTime) }}
                            <mf-icon class="time__icon" icon-name="icon-arrow" width="10" height="10" />
                            {{ displayTime(location.endTime) }}
                        </div>
                    </div>
                </div>
                <mf-divider
                    class="location__divider"
                    :key="`divider-${index}-${location.URN}`"
                    v-if="locations.length - 1 !== index"
                />
            </template>
        </div>
        <div slot="empty-text">
            {{ emptyText }}
        </div>
    </mf-box>
</template>

<script>
    import { mapGetters } from 'vuex';
    import dateFilter from '@/filters/date.filter';
    import MfDivider from '@/components/default/MfDivider';
    import formatStatus from '@/helpers/formatStatus.helper';
    const EMPTY_TIME = 'N/A';

    export default {
        name: 'PortcallLocations',
        components: { MfDivider },
        watch: {
            portCallId: {
                handler(val) {
                    val && this.$store.dispatch('getPortCallEvents', val);
                },
                immediate: true,
            },
        },
        computed: {
            ...mapGetters({ portCallId: 'selectedPortCallId', events: 'selectedPortCallEvents' }),
            locations() {
                return (
                    this.events &&
                    this.events
                        .filter(event => event.definitionId === 'VESSEL_AT_BERTH')
                        .map(event => {
                            let status = formatStatus(event.status);
                            status === 'warning' && (status = 'error');
                            return {
                                ...event.at,
                                startTime: event.startTime,
                                endTime: event.endTime,
                                status,
                            };
                        })
                );
            },
            emptyText() {
                return this.portCallId ? "Portcall doesn't contain any locations yet." : 'No portcall chosen';
            },
        },
        methods: {
            displayTime(time) {
                return !time ? EMPTY_TIME : dateFilter(time, 'location');
            },
            goToQuay(urn) {
                this.$router.push({ name: 'quay', params: { id: urn } });
            },
        },
    };
</script>

<style lang="scss" scoped>
    .location__name {
        font-weight: 600;
        font-size: $font-16;
        line-height: $font-24;
        color: $white;
        cursor: pointer;
    }

    .location__time {
        font-weight: 600;
        font-size: $font-14;
        line-height: $font-20;
        color: $marine-300;
    }

    .time__icon {
        margin: 0 $space-4;
    }

    .location {
        display: flex;
        align-items: center;
    }

    .location__divider {
        margin: $space-8 0;
    }

    .location__status {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: $space-16;
        padding: $space-6;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .location__status_check {
        background-color: $green-500;
    }

    .location__status_empty {
        background-color: $blue-500;
    }

    .location__status_error {
        background-color: $red-500;
    }
</style>
