<template>
    <mf-box without-inner-paddings :without-header="!isCustomPage" :empty="!portcall">
        <div slot="header">
            Portcall Preview
        </div>
        <div class="container vessel-info" v-if="portcall">
            <div class="container">
                <div class="container__row">
                    <div class="container__item">
                        <div class="vessel-info__status">
                            <vessel-image :portcall="portcall" />
                            <div class="vessel-info__port-call">
                                <div class="info__item portcall-item__header">
                                    {{ portcall.vessel.name }}
                                </div>
                                <div
                                    class="info__item portcall-item__date"
                                    v-if="!!(Date.parse(portcall.startTime) && Date.parse(portcall.endTime))"
                                >
                                    {{ portcall.startTime | dateFilter('portCall') }}
                                    <mf-icon
                                        class="portcall-item__icon"
                                        icon-name="icon-arrow"
                                        height="15"
                                        width="15"
                                    />
                                    {{ portcall.endTime | dateFilter('portCall') }}
                                </div>
                                <div class="info__item portcall-item__state">
                                    {{ portcall.lastUpdatedState }}
                                    <span class="portcall-item__by">
                                        by
                                        {{ portcall.lastUpdatedBy }}
                                    </span>
                                    <span class="portcall-item__updated-time">
                                        {{ portcall.lastUpdated | dateFilter('date, time') }}
                                    </span>
                                </div>
                            </div>
                            <div class="portcall-item__status">
                                <div
                                    class="portcall-item__circle"
                                    :class="`portcall-item__circle_${portcall.status}`"
                                ></div>
                                <div class="portcall-item__status-info">
                                    {{ portcall.stage }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container__item" :class="{ 'container__item_hide-large': !isCustomPage }">
                        <mf-button pattern="primary-outline" class="vessel-info__button" @click="openSidebar">
                            <mf-icon class="button__icon" icon-name="icon-list" height="24" width="24" />
                            Change Portcall
                        </mf-button>
                    </div>
                </div>
                <div class="container__row">
                    <div class="container__item vessel-info__specification">
                        <div class="container__row container__row_all-margins">
                            <div class="container__item">
                                <div>
                                    IMO:
                                    <span class="vessel-info__value">{{ portcall.vessel.imo }}</span>
                                </div>
                                <div>
                                    Call Sign:
                                    <span class="vessel-info__value">{{ portcall.vessel.callSign }}</span>
                                </div>
                            </div>
                            <div class="container__item">
                                <div>
                                    MMSI:
                                    <span class="vessel-info__value">{{ portcall.vessel.mmsi }}</span>
                                </div>
                                <div>
                                    Type:
                                    <span class="vessel-info__value">{{ portcall.vessel.vesselType }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container__item vessel-info__select">
                        <mf-select
                            placeholder="Add vessel to list"
                            :options="possibleVesselLists"
                            label="name"
                            @select="handleAddVesselToList"
                        >
                            <div slot="caret">
                                <mf-icon class="multiselect__select" icon-name="icon-down" />
                            </div>
                        </mf-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="container vessel-info" slot="empty">
            <mf-button pattern="primary-outline" class="vessel-info__button" @click="openSidebar">
                <mf-icon class="button__icon" icon-name="icon-list" height="24" width="24" />
                Change Portcall
            </mf-button>
        </div>
    </mf-box>
</template>

<script>
    import { mapGetters } from 'vuex';
    import VesselImage from '@/components/vessel-components/VesselImage';

    export default {
        name: 'VesselInfo',
        components: { VesselImage },
        props: {
            isCustomPage: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            ...mapGetters({ portcall: 'selectedPortCall', vesselLists: 'customVesselLists' }),
            possibleVesselLists() {
                return (
                    this.vesselLists &&
                    this.vesselLists.filter(
                        list => !list.vessels.some(vessel => vessel.mmsi === this.portcall.vessel.mmsi)
                    )
                );
            },
        },
        methods: {
            openSidebar() {
                this.$store.commit('setSidebarValue', 'portcall');
            },
            handleAddVesselToList(list) {
                this.$store.dispatch('addItemToCustomList', { list, item: this.portcall.vessel });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/sass/default/layout.scss';

    .vessel-info {
        display: flex;
        flex-direction: column;
        background: $marine-800;
        border-radius: 6px;
    }

    .vessel-info__status {
        display: flex;
        padding: $space-16;
        border: 1px solid $marine-500;
        box-sizing: border-box;
        border-radius: 6px;
        position: relative;
        flex-direction: column;
        @include media($sm) {
            flex-direction: row;
        }
    }

    .vessel-info__specification {
        display: flex;
        font-weight: normal;
        font-size: $font-14;
        line-height: $font-24;
        color: $white;
        padding-bottom: 0;
        width: 100%;
        flex-basis: 100%;
        @include media($lg) {
            width: 66.66%;
            flex-basis: 66.66%;
        }
    }

    .vessel-info__select {
        padding-bottom: 0;
        display: flex;
        width: 100%;
        flex-basis: 100%;
        @include media($lg) {
            width: 33.33%;
            flex-basis: 33.33%;
        }
    }

    .vessel-info__port-call {
        margin: (-$space-4) 0 (-$space-4) $space-16;
    }

    .vessel-info__button {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid $marine-500;
        align-self: stretch;
        height: 100%;
    }

    .container__item_hide-large {
        @include media($lg) {
            display: none;
        }
    }

    .button__icon {
        margin-bottom: $space-12;
    }

    .info__item {
        padding: $space-4 0;
    }

    .portcall-item__header {
        font-weight: 600;
        font-size: $font-24;
        line-height: $font-30;
        color: $white;
    }

    .portcall-item__date {
        display: flex;
        align-items: center;
        font-weight: normal;
        font-size: $font-16;
        line-height: $font-18;
        color: $marine-300;
    }

    .portcall-item__icon {
        margin: 0 0.5em;
    }

    .portcall-item__state {
        font-weight: bold;
        font-size: $font-14;
        line-height: $font-14;
        color: $marine-300;
    }

    .portcall-item__by {
        font-weight: normal;
    }

    .portcall-item__updated-time {
        font-weight: normal;
        font-style: italic;
    }

    .portcall-item__status {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        right: $space-12;
        height: $font-14;
        transform: rotate(-90deg);
        transform-origin: right bottom;
    }

    .portcall-item__circle {
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }

    .portcall-item__circle_check {
        background: $green-500;
    }

    .portcall-item__circle_warning {
        background: $orange-500;
    }

    .portcall-item__circle_error {
        background: $red-500;
    }

    .portcall-item__circle_empty {
        background: $blue-500;
    }

    .portcall-item__status-info {
        margin-left: $space-4;
        font-weight: normal;
        font-size: $font-12;
        line-height: $font-14;
        color: $white;
    }

    .vessel-info__value {
        font-weight: 700;
    }
</style>
