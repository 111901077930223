import { Api } from '@/services/apiService';
import dateFilter from '@/filters/date.filter';

export class chatApi {
    static index(portcallId, params) {
        return Api.get(`/portcall/${portcallId}/chat-messages`, { baseURL: '/new-api', params: params });
    }
    static store(params) {
        return Api.post(`/portcall/new-chat-message`, params, { baseURL: '/new-api' });
    }
}

export const subscribeToChatUpdates = (id, callback) => {
    Echo.channel(`new-message.${id}`).listen('NewChatMessage', event => {
        if (event && event.message) {
            event.message.createdAt = dateFilter(Date.parse(event.message.createdAt), 'date time');
            callback(event.message);
        }
    });
};

export function leaveFromChannel(id) {
    Echo.leave(`new-message.${id}`);
}
