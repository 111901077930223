<template>
    <div class="event" :style="event.style">
        <div class="event__line" @click="$emit('toggleModal', event)">
            <template v-if="event.showTime">
                <div class="event__icons">
                    <statement-icon
                        class="event__icon_small"
                        :type="event.startTimeType"
                        size="x-small"
                        without-rounded-corner="bottom-left"
                    />
                    <statement-icon
                        class="event__icon_small"
                        :type="event.endTimeType"
                        size="x-small"
                        without-rounded-corner="bottom-right"
                    />
                </div>
            </template>
            <template v-else>
                <div class="event__popup">
                    <div class="popup__line">
                        <statement-icon
                            class="event__icon_small"
                            :type="event.startTimeType"
                            size="x-small"
                            without-rounded-corner="bottom-left"
                        />
                        <div class="event__time event__time_small">
                            {{ displayTime(event.startTime) }}
                        </div>
                        <mf-icon class="event__icon" icon-name="icon-arrow" width="10" height="10" />
                        <div class="event__time event__time_small">
                            {{ displayTime(event.endTime) }}
                        </div>
                        <statement-icon
                            class="event__icon_small"
                            :type="event.endTimeType"
                            size="x-small"
                            without-rounded-corner="bottom-right"
                        />
                    </div>
                    <div class="popup__line" v-if="$slots.default">
                        <slot />
                    </div>
                </div>
            </template>
            <div class="event__times" :class="{ 'event__times_not-empty': event.showTime }">
                <template v-if="event.showTime">
                    <div class="event__time">
                        {{ displayTime(event.startTime) }}
                    </div>
                    <div class="event__time" v-if="$slots.default">
                        <slot />
                    </div>
                    <div class="event__time">
                        {{ displayTime(event.endTime) }}
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import StatementIcon from '../statement-components/StatementIcon';
    import dateFilter from '../../filters/date.filter';
    const EMPTY_TIME = 'N/A';

    export default {
        name: 'Event',
        components: { StatementIcon },
        props: {
            event: {
                type: Object,
                required: true,
            },
        },
        methods: {
            displayTime(time) {
                return !time ? EMPTY_TIME : dateFilter(time, 'time');
            },
        },
        data() {
            return {
                isModalShown: false,
            };
        },
    };
</script>

<style lang="scss" scoped>
    .event {
        position: absolute;
        padding: $space-8 0;

        display: flex;
        align-items: stretch;
        height: 48px;

        z-index: 1;
    }

    .event__line {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;

        position: relative;

        &:hover {
            opacity: 0.7;
            cursor: pointer;
            .event__popup {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .event__times {
        background: $marine-400;
        border: 1px solid $marine-300;
        border-radius: 2px;

        height: 14px;

        display: flex;
        justify-content: space-between;
    }

    .event__times_not-empty {
        padding: 0 $space-4;
    }

    .event__time {
        font-weight: 600;
        font-size: $font-12;
        line-height: $font-14;
        color: $white;
        opacity: 0.6;
    }

    .event__time_small {
        margin: 0 $space-4;
        font-size: $font-10;
        opacity: 1;
    }

    .event__icon {
        margin: 0 $space-4;
        color: $white;
    }

    .event__icons {
        display: flex;
        justify-content: space-between;
        height: 14px;
        margin-bottom: $space-4;
    }

    .event__popup {
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        display: none;
        align-items: center;
        margin-bottom: $space-4;
    }

    .popup__line {
        display: flex;
        font-size: $font-10;
        color: $white;
    }
</style>
