<template>
    <div class="statement__preview">
        <div class="preview">
            <div class="preview__item">
                {{ state.name }}
            </div>
            <div class="preview__item">
                {{ state.time | dateFilter('date, time') }}
            </div>
            <div class="preview__item">Time type: {{ state.timeType }}</div>
            <div v-if="state.comment && state.comment.length" class="preview__item">Comment: {{ state.comment }}</div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            state: {
                type: Object,
                default: () => {},
            },
        },
    };
</script>
<style lang="scss" scoped>
    .statement__preview {
        display: flex;
        align-items: center;
        text-align: center;
        height: auto;
        min-height: 120px;
        width: 110px;
        background-color: $marine-400;
        color: $white;
        border-radius: 20px;
        font-size: 15px;
        word-break: break-word;
    }

    .preview {
        padding: $space-8 $space-4;
        font-size: 14px;
    }

    .preview__item {
        line-height: 14px;
        margin-bottom: $space-8;
        user-select: auto;
        cursor: text;
    }
</style>
