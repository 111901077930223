<template>
    <div class="time-labels">
        <div v-for="(timeLabel, index) in timeLabels" :key="index">
            <div class="time-label">
                <div class="time-label__date" v-if="timeLabel.isNewYear || timeLabel.isNewMonth || timeLabel.isNewDay">
                    {{ timeLabel.time | dateFilter('date') }}
                </div>
                <div class="time-label__time">
                    {{ timeLabel.time | dateFilter('time') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TimeLabels',
        props: {
            startDate: {
                type: Number,
                required: true,
            },
            numberOfTimeLabels: {
                type: Number,
                required: true,
            },
            timeCoefficient: {
                type: Number,
                required: true,
            },
        },
        computed: {
            timeLabels() {
                let labels = [];

                const firstLabel = Math.floor(this.startDate);

                labels.push({
                    time: firstLabel,
                    isNewYear: true,
                    isNewMonth: true,
                    isNewDay: true,
                });

                let amountOfCreatedLabels = 1;

                while (amountOfCreatedLabels <= this.numberOfTimeLabels) {
                    let thisLabel = firstLabel + amountOfCreatedLabels * this.timeCoefficient;
                    let thisDate = new Date(thisLabel);
                    let previousDate = new Date(labels[amountOfCreatedLabels - 1].time);
                    let timelineLabel = {
                        time: thisLabel,
                        isNewYear: thisDate.getYear() !== previousDate.getYear(),
                        isNewMonth: thisDate.getMonth() !== previousDate.getMonth(),
                        isNewDay: thisDate.getDay() !== previousDate.getDay(),
                    };

                    labels.push(timelineLabel);
                    amountOfCreatedLabels++;
                }

                return labels;
            },
        },
    };
</script>

<style lang="scss" scoped>
    .time-labels {
        display: flex;
        margin-left: 170px;
    }

    .time-label {
        display: flex;
        align-items: flex-end;
        height: 40px;
        width: 120px;
        color: $white;
        position: relative;
    }

    .time-label__time {
        flex: 1 1 auto;
        font-size: $font-12;
        line-height: $font-18;
        border: 1px solid $marine-500;
        padding-left: 5px;
        border-top: transparent;
        height: 16px;
    }

    .time-label__date {
        position: absolute;
        top: 0;
        left: 1px;
        font-weight: bold;
        font-size: $font-14;
        line-height: $font-18;
    }
</style>
