<template>
    <mf-modal small v-if="isOpened" @close="close">
        <div slot="header">
            Create new state
        </div>
        <div class="create-modal">
            <transition name="fade">
                <div v-if="isLoading" class="create-modal__send-message-info">
                    Sending data...
                </div>
            </transition>
            <div class="create-modal__body">
                <div class="create-modal__line">
                    <span class="create-modal__label">State Name:</span>
                    <div class="create-modal__input">
                        <mf-input placeholder="Name" :value="newState.name" @input="handleInputName" />
                        <p class="create-modal__input-comment">StateId will be: {{ newState.stateId }}</p>
                    </div>
                </div>
                <div class="create-modal__line">
                    <span class="create-modal__label">Description:</span>
                    <mf-input class="create-modal__input" placeholder="Description" v-model="newState.description" />
                </div>
                <div class="create-modal__types">
                    <span class="create-modal__label">State Type:</span>
                    <div class="create-modal__radio-block">
                        <mf-radio
                            class="create-modal__state-type"
                            v-for="type in availableStateTypes"
                            :checked="newState.stateType === type"
                            @change="changeType(type)"
                            :key="`radio-${type}`"
                        >
                            {{ type | capitalize }}
                        </mf-radio>
                    </div>
                </div>
                <div class="create-modal__line">
                    <span class="create-modal__label">Object:</span>
                    <mf-input
                        class="create-modal__input"
                        placeholder="Object"
                        @input="handleInputObject"
                        :value="newState.object"
                    />
                </div>
                <mf-select
                    class="create-modal__select"
                    placeholder="Choose time sequence"
                    :options="displayedTimeSequences"
                    v-model="newState.timeSequence"
                    :disabled="!displayedTimeSequences.length"
                    :open-direction="'top'"
                >
                    <div slot="caret">
                        <mf-icon class="multiselect__select" icon-name="icon-down" />
                    </div>
                </mf-select>
                <mf-select
                    class="create-modal__select"
                    placeholder="Choose type"
                    :options="displayedTypes"
                    v-model="newState.type"
                    :disabled="!displayedTypes.length"
                >
                    <div slot="caret">
                        <mf-icon class="multiselect__select" icon-name="icon-down" />
                    </div>
                </mf-select>
            </div>
        </div>
        <div slot="footer">
            <div class="modal__buttons">
                <mf-button class="modal__button" @click="close">Cancel</mf-button>
                <mf-button class="modal__button" color="blue" @click="create">Create</mf-button>
            </div>
        </div>
    </mf-modal>
</template>

<script>
    import { startCase, cloneDeep } from 'lodash';
    import { mapGetters } from 'vuex';
    import formatPortcallStateDefinitions from '@/helpers/formatPortcallStateDefinitions.helper';

    const AVAILABLE_STATE_TYPES = ['ServiceState', 'LocationState', 'AdministrationState'];

    const TEST_LIST_OF_TIME_SEQUENCES = {
        ServiceState: ['COMMENCED', 'COMPLETED', 'CEASED', 'RESUMED'],
        LocationState: ['ARRIVAL_TO', 'DEPARTURE_FROM'],
        AdministrationState: ['REQUESTED', 'CONFIRMED', 'CANCELLED', 'DENIED', 'REQUEST_RECEIVED'],
    };

    const TEST_LIST_OF_TYPES = {
        ServiceState: ['STATIONARY', 'NAUTICAL'],
        LocationState: [
            'VESSEL',
            'TUG_ZONE',
            'TRAFFIC_AREA',
            'RENDEZV_AREA',
            'PORT_AREA',
            'PILOT_BOARDING_AREA',
            'LOC',
            'HOME_BASE',
            'ETUG_ZONE',
            'BERTH',
            'ANCHORING_AREA',
        ],
        AdministrationState: ['STATIONARY', 'NAUTICAL'],
    };

    export default {
        name: 'CreateStateModal',
        props: {
            isOpened: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                availableStateTypes: AVAILABLE_STATE_TYPES,
                availableTimeSequences: TEST_LIST_OF_TIME_SEQUENCES,
                availableTypes: TEST_LIST_OF_TYPES,
                newState: {
                    stateId: '',
                    stateType: '',
                    name: '',
                    object: '',
                    timeSequence: '',
                    type: '',
                    description: '',
                    critical: false,
                    createdBy: 'Test',
                },
                comment: '',
                isLoading: false,
            };
        },
        watch: {
            'newState.name': {
                handler(value) {
                    if (value && value.length) {
                        this.newState.stateId = value.replace(/\s+/g, '_');
                    }
                },
            },
            'newState.stateType': {
                handler(value) {
                    if (value && value.length) {
                        this.newState.timeSequence = this.newState.type = '';
                    }
                },
            },
        },
        computed: {
            ...mapGetters(['statesDefinitions']),
            displayedTimeSequences() {
                return (this.newState?.stateType && this.availableTimeSequences[this.newState.stateType]) || [];
            },
            displayedTypes() {
                return (this.newState?.stateType && this.availableTypes[this.newState.stateType]) || [];
            },
        },
        methods: {
            handleInputName(value) {
                this.newState.name = startCase(value);
            },
            changeType(value) {
                this.newState.stateType = value;
            },
            handleInputObject(value) {
                this.newState.object = value.toUpperCase().replace(/\s+/g, '_');
            },
            close() {
                this.$emit('close');
            },
            create() {
                let state = cloneDeep(this.newState);
                if (this.statesDefinitions.find(stateDefinition => stateDefinition.stateId === state.stateId)) {
                    this.$toasted.error('State is already exist!');
                }
                // remove this formatting before sending to backend
                state = formatPortcallStateDefinitions([state])[0];
                this.$store.commit('setStatesDefinitions', [state, ...this.statesDefinitions]);
                this.close();
            },
        },
    };
</script>

<style scoped lang="scss">
    .create-modal {
        display: flex;
        flex-direction: column;
    }

    .create-modal__send-message-info {
        position: absolute;
        top: 20px;
        right: 5%;
        padding: $space-12;
        background-color: $white;
        border-radius: $border-radius-4;
        border: 1px solid $marine-500;
        @include media($md) {
            top: 5%;
        }
    }

    .create-modal__body {
        padding: $space-8;
    }

    .create-modal__line {
        display: flex;
        align-items: flex-start;
        margin-bottom: $space-24;
    }

    .create-modal__label {
        width: 25%;
        text-align: end;
        color: $white;
        padding: 15px 12px 15px 0;
    }

    .create-modal__input {
        width: 75%;
    }

    .create-modal__input-comment {
        margin-top: $space-6;
        color: $white;
        opacity: 0.8;
    }

    .create-modal__types {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: $space-24;
    }

    .create-modal__radio-block {
        width: 75%;
        margin-top: 12px;
    }

    .create-modal__state-type:not(:last-child) {
        padding: 0 0 $space-20;
    }

    .create-modal__select {
        margin-bottom: $space-24;
    }

    .modal__buttons {
        display: flex;
        justify-content: flex-end;
    }

    .modal__button:not(:first-child) {
        margin-left: $space-8;
    }
</style>
